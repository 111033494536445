/* @jsx jsx */
import { jsx } from "theme-ui";
import { PageProps, Link } from "gatsby";
import { useMemo } from "react";
import NotionRenderer from "../components/NotionRenderer";
import { Layout } from "../components/Layout";
import GlobalPrismStyles from "../components/GlobalPrismStyles";
import { ReactComponent as Chevron } from "../../static/chevron.svg";
import SEO from "../components/Seo";
import "react-notion/dist/styles.css";
import tw from "twin.macro";
import { BlockMapType } from "react-notion";

export default function HelpPage({
  pageContext: { blockMap, title, description },
  location,
}: PageProps<{}, { blockMap: string; title: string; description: string }>) {
  const json: BlockMapType = useMemo(() => JSON.parse(blockMap), [blockMap]);
  const isRoot = /\/help\/?$/.test(location.pathname);

  return (
    <Layout centered={false} widgetCTA={isRoot ? "More questions?" : undefined}>
      <SEO title={title} description={description} />
      <GlobalPrismStyles />
      <article sx={tw`mx-auto max-w-3xl`}>
        {!isRoot && (
          <Link
            to="/help"
            sx={{
              color: `blue.6`,
              textDecoration: `none`,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Chevron sx={{ transform: `rotate(180deg)`, mr: 2 }} /> Back to help
            center
          </Link>
        )}
        <h1
          sx={{
            mb: 0,
            fontSize: 5,
            fontWeight: "bold",
            fontFamily: "PT Serif",
          }}
        >
          {title}
        </h1>
        <div
          sx={{
            ".notion > .notion-page-link": {
              padding: 0,
            },
          }}
        >
          <NotionRenderer blockMap={json} />
        </div>
      </article>
    </Layout>
  );
}
